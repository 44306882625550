const axiosInstance = require('axios');

const url = process.env.GATSBY_WEBINAR_API;
// wordpress axios instance
const axios = axiosInstance.create({
  baseURL: url,
  headers: {
    'Content-Type': 'application/json'
  }
});

// export function to call wp api
module.exports = async function fetchWebinar(params, postParams) {
  const type = postParams ? 'post' : 'get';
  try {
    const { data } = await axios[type](params, postParams || {});
    return data;
  } catch (e) {
    throw e;
  }
};
