/* eslint-disable implicit-arrow-linebreak  */
import React, { useEffect, useState } from 'react';
import Container from 'container';
import Headline from 'headline';
import Loading from 'shared/loading';
import Button from 'button';
import fetchWebinar from 'api/webinar';
import { useFormInput, useErrors, useSubmitReady } from 'hooks/useForm';
import fetchWordpress from 'api/wordpress';

import s from './orgasmic-manifesting-system.module.scss';

const timeType = {
  0: 'hours',
  1: 'minutes',
  2: 'seconds'
};

export default function OrgasmicManifestingSystem() {
  // CUSTOM  HOOKS
  const email = useFormInput('');
  const name = useFormInput('');
  const emailErrors = useErrors({ email: email.state });
  const nameErrors = useErrors({ name: name.state });
  const textInputsReady = useSubmitReady({
    email: email.state,
    name: name.state
  });

  // STATE
  const [allWebinars, setAllWebinars] = useState(null);
  const [webinarId, setWebinarId] = useState(null);
  const [webinarTime, setWebinarTime] = useState('');

  const fetchWebinars = async () => {
    const webinars = await fetchWordpress(`/posts?filter[meta_key]=webinar_type&filter[meta_value]=automated`);
    const currentTime = new Date(Date.now());
    currentTime.setMinutes(
      (Math.ceil(currentTime.getMinutes() / 3) * 3) % 60
    );
    if (currentTime.getMinutes() === 0) {
      if (currentTime.getHours === 23) {
        currentTime.setDate(currentTime.getDate() + 1);
        currentTime.setHours(0);
      }
      currentTime.setHours(currentTime.getHours() + 1);
    }
    const webinarTimes = [
      ...webinars[0].acf.automated_webinar.available_times
        .map(({ time }) => time)
        .map(time => {
          const newTime = time
            .split(':')
            .reduce(
              (acc, curr, idx) => ({ ...acc, [timeType[idx]]: Number(curr) }),
              {}
            );
          return newTime;
        })
        .filter(time => time.hours !== currentTime.getHours()),
      {
        hours: currentTime.getHours(),
        minutes: currentTime.getMinutes(),
        seconds: 0
      }
    ];
    const mappedWebinarTimes = webinarTimes.map(date => {
      const timeOfDay = date.hours < 12 ? 'am' : 'pm';
      const hour = timeOfDay === 'am' ? date.hours : date.hours - 12;
      const minutes = date.minutes < 10 ? `0${date.minutes}` : date.minutes;
      const timestamp = new Date();
      timestamp.setHours(date.hours);
      timestamp.setMinutes(date.minutes);
      timestamp.setSeconds(0);
      return {
        ...date,
        localTime: `${hour === 0 ? 12 : hour}:${minutes}${timeOfDay}`,
        timestamp: timestamp.getTime()
      };
    });
    await setWebinarId(webinars[0].id);
    setAllWebinars(mappedWebinarTimes);
  };

  // EFFECTS
  const submitReady = textInputsReady && webinarTime;
  const handleWebinarTime = e => {
    const { value } = e.target;
    setWebinarTime(Number(value));
  };
  const handleSubmit = async e => {
    e.preventDefault();

    const form = {
      email: email.state.value,
      name: name.state.value,
      webinar_time: webinarTime,
      webinar_id: webinarId,
      admin: false,
      permissions: false
    };
    const res = await fetchWebinar('/', form);
    email.setters.setValue('');
    name.setters.setValue('');
    setWebinarTime('');
    console.log(res);
  };

  useEffect(() => {
    fetchWebinars();
  }, []);

  return allWebinars ? (
    <Container className={s.root}>
      <Headline h1>Sign up</Headline>
      <br />
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="email"
          {...email.events}
          value={email.state.value}
        />
        <br />
        <br />
        <input
          type="text"
          name="name"
          {...name.events}
          value={name.state.value}
        />
        <br />
        <br />
        {allWebinars.map(({ localTime, timestamp }) => (
          <div key={timestamp}>
            <label htmlFor={timestamp}>
              <input
                type="radio"
                value={timestamp}
                id={timestamp}
                checked={timestamp === webinarTime}
                onChange={handleWebinarTime}
              />
              <span className={s.radioLabel}>{localTime}</span>
            </label>
          </div>
        ))}
        <br />
        <Button
          tertiary
          type="submit"
          disabled={!submitReady}
          className={s.submit}
        >
          Submit
        </Button>
      </form>
    </Container>
  ) : (
    <Loading />
  );
}
